// 自定义配置，参考 ./default/setting.config.js，需要自定义的属性在这里配置即可
module.exports = {
  theme: {
    color: '#3F8BFF',
    mode: 'light',
    success: '#52c41a',
    warning: '#faad14',
    error: '#f5222f',
    zdy: '#3F8BFF'
  },
  asyncRoutes: true,       //异步加载路由，true:开启，false:不开启
  multiPage: true,
  animate: {
    disabled: false,
    name: 'lightSpeed',
    direction: 'left'
  }
}
      