// 视图组件
const view = {
  tabs: () => import('@/layouts/tabs'),
  blank: () => import('@/layouts/BlankView'),
  page: () => import('@/layouts/PageView')
}

// 路由组件注册
const routerMap = {
  login: {
    authority: '*',
    path: '/login',
    component: () => import('@/pages/login')
  },
  root: {
    path: '/',
    name: '登录页',
    redirect: '/login',
    component: view.tabs
  },
  dashboard: {
    name: '工作台',
    icon: 'dashboard',
    component: view.blank
  },
  workplace: {
    name: '首页',
    component: () => import('@/pages/dashboard/workplace/WorkPlace')
  },
  warehouse: {
    name: '仓库管理',
    icon: 'bank',
    component: view.blank
  },
  warehouse_list: {
    name: '仓库列表',
    component: () => import('@/pages/srm_warehouse/index')
  },

  resources: {
    name: '资质管理',
    icon: 'safety-certificate',
    component: view.blank
  },
  resources_conpany: {
    name: '公司资质',
    component: () => import('@/pages/srm_resources/company/index')
  },
  resources_manufacturer:{
    name: '厂商资质',
    component: () => import('@/pages/srm_resources/manufacturer/index')
  },
  resources_brand:{
    name: '品牌资质',
    component: () => import('@/pages/srm_resources/brand/index')
  },
  resources_credentia:{
    name: '商品资质',
    component: () => import('@/pages/srm_resources/credentia/index')
  },
  resources_classify:{
    name: '分类资质',
    component: () => import('@/pages/srm_resources/classify/index')
  },
  product:{
    name: '我的商品',
    icon: 'gift',
    component: view.blank    
  },
  product_spu:{
    name: '我的spu商品',
    component: () => import('@/pages/srm_product/spu/index')
  },
  product_spu_form:{
    name: '新增spu商品',
    component: () => import('@/pages/srm_product/spu/components/sku_form')
  },
  product_spu_formEdit:{
    name: '编辑spu商品',
    component: () => import('@/pages/srm_product/spu/components/sku_form')
  },
  product_sku_formDetails:{
    name: 'sku商品',
    component: () => import('@/pages/srm_product/sku/components/sku_form')
  },
  product_spu_formDetails:{
    name: '商品详情',
    component: () => import('@/pages/srm_product/spu/components/sku_form')
  },
  product_sku:{
    name: '我的sku商品',
    component: () => import('@/pages/srm_product/sku/index')
  },
  srm_orders:{
    name: '订单管理',
    icon: 'unordered-list',
    component: view.blank    
  },
  srm_orders_list:{
    name: '普通订单',
    component: () => import('@/pages/srm_orders/list/index')
  },
  sfa_sales_programs: {
    name: '销售方案',
    icon: 'file-search',
    component: view.blank
  },
  sfa_workOrder: {
    name: '工单管理',
    icon: 'menu-unfold',
    component: view.blank
  },
  product_solution: {
    name: '产品方案',
    component: () => import('@/pages/programme/QueryList'),
  },
  OutboundList: {
    name: '卡券销售单',
    component: () => import('@/pages/sfa_workOrder/OutboundList'),
  },
  InvoiceApplyList: {
    name: '发票申请',
    component: () => import('@/pages/sfa_workOrder/InvoiceApplyList'),
  },
  sfa_applyActivity: {
    name: '活动申请',
    component: () => import('@/pages/sfa_workOrder/sfa_applyActivity'),
  },
  addInvoiceApply: {
    name: '发票申请单',
    component: () => import('@/pages/sfa_workOrder/addInvoiceApply'),
  },
  editInvoiceApply:{
    name: '发票申请单详情',
    component: () => import('@/pages/sfa_workOrder/editInvoiceApply'),
  },
  addWorkOrder :{
    name: '新建',
    component: () => import('@/pages/sfa_workOrder/addWorkOrder'),
  },
  detailsWorkOrder:{
    name: '详情',
    component: () => import('@/pages/sfa_workOrder/detailsWorkOrder'),
  },
  editWorkOrder:{
    name: '编辑',
    component: () => import('@/pages/sfa_workOrder/editWorkOrder'),
  },
  query_form: {
    name: '商品列表',
    component: () => import('@/pages/selection/QueryList'),
  },

  sfa_commodity_management: {
    name: '商品管理',
    icon: 'table',
    component: view.blank
  },
  product_library:{
    name: '商品库',
    component: () => import('@/pages/selection/productLibrary')
  },

  sfa_mine: {
    name: '个人中心',
    icon: 'user',
    component: view.blank
  },

  sfa_goOut: {
    name: '外出管理',
    icon:'logout',
    component: view.blank
  },
  sfa_goOut_visit: {
    path: 'sfa_goOut_visit',
    name: '拜访记录',
    component: () => import('@/pages/sfa_goOut/sfa_goOut_visit')
  },
  sfa_telephoneRecord: {
    name: '通话数据',
    icon: 'phone',
    component: view.blank
  },
  sfa_history_record: {
    path: 'sfa_history_record',
    name: '通话记录',
    component: () => import('@/pages/sfa_telephoneRecord/sfa_history_record')
  },
  recordList: {
    path: 'recordList',
    name: '通话数据',
    component: () => import('@/pages/sfa_telephoneRecord/recordList')
  },
  workloadList: {
    path: 'workloadList',
    name: '通话工作量',
    component: () => import('@/pages/sfa_telephoneRecord/workloadList')
  },
 
  sfa_financial: {
    name: '销售数据',
    icon: 'money-collect',
    component: view.blank
  },
  statistics: {
    path: 'statistics',
    name: '销售看板',
    component: () => import('@/pages/sfa_financial/statistics')
  },
  sfa_cargo_pile:{
    path: 'sfa_cargo_pile',
    name: '货堆明细',
    component: () => import('@/pages/sfa_financial/sfa_cargo_pile')
  },
  sfa_residential_allocation:{
    path: 'sfa_residential_allocation',
    name: '宅配明细',
    component: () => import('@/pages/sfa_financial/sfa_residential_allocation')
  },
  sfa_card_history:{
    path: 'sfa_card_history',
    name: '卡券明细',
    component: () => import('@/pages/sfa_financial/sfa_card_history')
  },
  exportTaskList:{
    name: '导出任务列表',
    component: () => import('@/pages/programme/ExportTask')
  },

  sfa_resource: {
    name: '资源管理',
    icon: 'pic-right',
    component: view.blank
  },
  sfa_brands_offline:{
    name: '线下品牌',
    component: () => import('@/pages/sfa_resource/sfa_brands_offline'),
  },
  sfa_goods_list:{
    name: '商品池列表',
    component: () => import('@/pages/sfa_resource/sfa_goods_list'),
  },
  sfa_cake_online:{
    name: '线上蛋糕品牌',
    component: () => import('@/pages/sfa_resource/sfa_cake_online'),
  },
  sfa_storeList:{
    name: '门店列表',
    component: () => import('@/pages/sfa_resource/sfa_storeList'),
  },
  sfa_cinemaList:{
    name: '全国影院',
    component: () => import('@/pages/sfa_resource/sfa_cinemaList'),
  },

  sfa_customer: {
    name: '客户管理',
    icon: 'team',
    component: view.blank
  },
  sfa_customer_list:{
    name: '客户列表',
    component: () => import('@/pages/sfa_customer/sfa_customer_list'),
  },
  sfa_customer_statistics:{
    name: '客户统计',
    component: () => import('@/pages/sfa_customer/sfa_customer_statistics'),
  },
  sfa_customerMineList:{
    name: '我的客户',
    component: () => import('@/pages/sfa_customer/sfa_customerMineList'),
  },
  customer_list_All:{
    name: '公海客户',
    component: () => import('@/pages/customerAll/list'),
  },
  sfa_customer_details:{
    name: '客户列表详情',
    component: () => import('@/pages/sfa_customer/sfa_customer_details'),
  },
  addForm:{
    name: '编辑',
    component: () => import('@/pages/sfa_customer/addForm/AdvancedForm'),
  },
  InvoiceList:{
    name: '发票列表',
    component: () => import('@/pages/sfa_customer/InvoiceList'),
  },
  Contact:{
    name: '联系人',
    component: () => import('@/pages/sfa_customer/Contact'),
  },
  ContactAddress:{
    name: '联系人收货地址',
    component: () => import('@/pages/sfa_customer/ContactAddress'),
  },


 
}
export default routerMap

